<template>
  <div>
      <!--
    <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div id="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div id="v-step-2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
-->
    <v-tour name="eoTour" :steps="steps" :callbacks="myCallbacks">
      <template slot-scope="tour">
    <transition name="fade">
      <v-step
        v-if="tour.steps[tour.currentStep]"
        :key="tour.currentStep"
        :step="tour.steps[tour.currentStep]"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :skip="tour.skip"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="tour.labels"
      >
        <template v-if="tour.currentStep === 5">
          <div slot="actions">
            <button @click="tour.skip" class="v-step__button v-step__button-previous">Skip tour</button>
            <button @click="tour.previousStep" class="v-step__button v-step__button-previous">Previous step</button>
            <button @click="tour.stop" class="v-step__button v-step__button-skip">Got it!</button>
          </div>
        </template>
      </v-step>
    </transition>
  </template>
    </v-tour>
  </div>
</template>

<script>
  export default {
    name: 'eo-tour',
    data () {
      return {
        steps: [
          {
            target: '#EO-step-1',  // We're using document.querySelector() under the hood
            header: {
              title: 'Search for expedited orders',
            },
            content: `<p style="font-weight: 300;">This area will allow you to search expedited orders by order number, PO number, or order name.</p>`,
            params: {
              placement: 'left-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#EO-step-2',
            header: {
              title: 'Search expedited orders by AC',
            },
            content: `
            <p style="font-weight: 300;">This area will allow you to search expedited orders by a specific area contractor.</p>
            `,
             params: {
              placement: 'right-end', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#EO-step-3',
            header: {
              title: 'Search by expedited order status',
            },
            content: '<p style="font-weight: 300;">The controls in this section will enable you to search via all, pending, or completed expedited orders.</p>',
            params: {
              placement: 'left-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#EO-step-4',
            header: {
              title: 'Search or clear',
            },
            content: '<p style="font-weight: 300;">This will enable you to search, or clear your search.</p>',
            params: {
              placement: 'left-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#EO-step-5',
            header: {
              title: 'Expedited order details',
            },
            content: '<p style="font-weight: 300;">Click this link to view the details of the expedited order.</p>',
            params: {
              placement: 'right-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#EO-step-6',
            header: {
              title: 'Order details',
            },
            content: '<p style="font-weight: 300;">Click this link to view the details of the linked order.</p>',
            params: {
              placement: 'right-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          }

        ],
        myCallbacks: {
				  onSkip: this.customSkipStepCallback
			  },
      }
    },
    methods: {
      customSkipStepCallback: function () {
			  console.log('Hello');
        this.$store.commit("hideTour");
        this.$noty.success("The help tour has been disabled, you can enable it again in the top menu under your name!");
		  },
    },
    mounted: function () {
      this.$tours['eoTour'].start();
    }

  }
</script>
<style>
.v-step {
    /*background: #F16231!important;*/
    background: #50596c;
    background: #5f6d7b!important;
    color: #fff;
    max-width: 320px;
    border-radius: 3px;
    -webkit-filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    padding: 1rem;
    text-align: center;
    z-index: 10000;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.18);
}
.v-step__header {
    margin: -1rem -1rem .5rem;
    padding: .5rem;
    background-color: #454d5d;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

</style>
